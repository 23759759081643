<template>
    <refund-module-component/>
</template>

<script>
    import RefundModuleComponent from "@/components/admin/RefundModuleComponent";

    export default {
        name: "RefundModule",
        title: "Fondo de Caja y Ahorro | Private",
        components: { RefundModuleComponent }
    }
</script>

<style scoped>

</style>